import axios from '@axios'

export default class DataService {
    apiUrl = "fotostudio";

    getArticles(params) {
        return axios.get(`${this.apiUrl}/articles`, {params});
    }

    getFields(sku, params = null) {
        return axios.get(`${this.apiUrl}/fields/${sku}`, {params});
    }

    confirmArticleByStaff(sku, data) {
        return axios.put(`${this.apiUrl}/confirm/${sku}`, {data});
    }

    search(ean, params = null) {
        return axios.get(`${this.apiUrl}/articles/search/${ean}`, {params});
    }

    getPhotographerFields(ean, params = null) {
        return axios.get(`${this.apiUrl}/photographer/fields/${ean}`, {params});
    }

    confirmArticleByPhotographer(ean, data) {
        return axios.put(`${this.apiUrl}/photographer/confirm/${ean}`, {data});
    }

    getOptions(params = null) {
        return axios.get(`${this.apiUrl}/options`, {params});
    }

    getGroups(params = null) {
        return axios.get(`${this.apiUrl}/groups`, {params});
    }

    addOption(data) {
        return axios.post(`${this.apiUrl}/options`, data);
    }

    updateOptions(data) {
        return axios.put(`${this.apiUrl}/options`, {data});
    }

    deleteOption(id) {
        return axios.delete(`${this.apiUrl}/options/${id}`);
    }
}
