<template>
  <b-card>
    <div class="d-flex justify-content-between align-items-center font-medium-3 mb-2">
      <div>
        <b v-if="sku !== ''">{{ $i18n.t('fotostudio.sku') }}:</b> {{ sku }}
        <b v-if="ean !== ''">{{ $i18n.t('fotostudio.ean') }}:</b> {{ ean }}
      </div>
    </div>
    <b-form
        @submit.prevent="()=>{}"
    >
      <b-row>
        <b-col
            cols="12"
            md="9"
        >
          <b-row>
            <b-col
                v-for="(field, index) in fields"
                :key="index"
                :cols-="field.type === 'textarea' ? 12 : 6"
                :md="field.type === 'textarea' ? 12 : 4"
                class="pt-1"
            >
              <div v-if="field.type === 'text'">
                <label>{{ getTranslatedName(field) }}</label>
                <b-form-input
                    v-model="field.value"
                    :disabled="isDisabledField(field)"
                />
              </div>
              <div v-if="field.type === 'textarea'">
                <label>{{ getTranslatedName(field) }}</label>
                <b-form-textarea
                    v-model="field.value"
                    :disabled="isDisabledField(field)"
                />
              </div>
              <div v-if="field.type === 'dropdown'">
                <label>{{ getTranslatedName(field) }}</label>
                <v-select
                    v-model="field.value"
                    :clearable="false"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :disabled="isDisabledField(field)"
                    :label="getTranslatedOption"
                    :options="field.options"
                    :reduce="option => option.id"
                    class="w-100"
                />
              </div>
              <div v-if="field.type === 'double'">
                <label>{{ getTranslatedName(field) }}</label>
                <b-input-group>
                  <b-form-input
                      v-model="field.value"
                      :disabled="isDisabledField(field)"
                      type="number"
                  />
                  <b-input-group-append v-if="field.unit">
                    <div class="d-flex align-items-center unit">
                      {{ field.unit }}
                    </div>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div
                  v-if="field.type === 'boolean'"
                  class="h-100 d-flex align-items-center"
              >
                <b-form-checkbox
                    v-model="field.value"
                    :disabled="isDisabledField(field)"
                    :name="`checkbox-${field.group_id}`"
                >
                  <label>{{ getTranslatedName(field) }} </label>
                </b-form-checkbox>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col
            cols="12"
            md="3"
        />
        <b-col
            cols="12"
        >
          <div v-if="images.length > 1">
            <h3 class="mt-3 pb-1">
              {{ $i18n.t('fotostudio.photos') }}
            </h3>
            <b-row class="mb-2">
              <b-col
                  v-for="(imageField,index) in images"
                  :key="index"
                  cols="6"
                  md="3"
              >
                <div class="pt-1 w-100">
                  <img
                      :alt="getTranslatedName(imageField)"
                      :src="imageField.fullPath"
                      class="image"
                  >
                  <b-form-input
                      v-model="imageField.name"
                      class="mt-2 mb-1"
                      disabled
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <div
              v-else
              class="mt-5 mb-1 text-center"
          >
            <h4> {{ $i18n.t('fotostudio.no_images') }}</h4>
          </div>
        </b-col>
        <b-col
            cols="12"
        >
          <div class="w-100 d-flex justify-content-between align-items-center">
            <b-button
                v-if="backButton"
                class="mt-2 mb-2 mr-0"
                variant="primary"
                @click="back()"
            >
              {{ $i18n.t('fotostudio.back') }}
            </b-button>
            <b-button
                :disabled="images.length === 0"
                class="mt-2 mb-2 mr-0"
                type="submit"
                variant="primary"
                @click="confirm()"
            >
              {{ confirmButtonName === '' ? $i18n.t('fotostudio.confirm') : confirmButtonName }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>
<script>
import boolean from "vue-good-table/src/components/types/boolean";

export default {
  emits: ['confirm', 'back'],
  props: {
    sku: {
      type: String,
      required: false,
      default: "",
    },
    ean: {
      type: String,
      required: false,
      default: "",
    },
    data: {
      type: Object,
      required: true,
    },
    backButton: {
      type: Boolean,
      default: false,
    },
    confirmButtonName: {
      type: String,
      required: false,
      default: '',
    }
  },
  data() {
    return {
      fields: [],
      images: [],
      skuData: this.sku,
      loading: false,
    }
  },
  computed: {
    getTranslatedName() {
      return (field) => {
        const lang = this.$i18n.locale;
        if (lang === "en") return field.name_en
        if (lang === "pl") return field.name_pl
        return field.name_de
      }
    },
    getTranslatedOption() {
      const lang = this.$i18n.locale;
      if (lang === "en") return 'value_en'
      if (lang === "pl") return 'value_pl'
      return 'value_de'
    }
  },
  watch: {
    data(newData) {
      this.handleData(newData)
    }
  },
  methods: {
    handleData(data) {
      this.images = data.images
      const fields = data.fields
      this.fields = []
      fields.forEach((item) => {
        const field = item;
        if (field.type === "boolean") {
          field.value = (field.value === "1");
        }
        this.fields.push(field)
      });
    },
    back() {
      this.$emit('back')
    },
    confirm() {
      const data = {};
      data.fields = this.fields.map(({group_id, index, value}) => ({group_id, index, value}));
      data.images = this.images;
      this.$emit('confirm', data)
    },
    isDisabledField(field) {
      return field.read_only === 1
    }
  }
}
</script>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style scoped>
.unit {
  background-color: #dfdfdf;
  border-radius: 0 3px 3px 0px;
  padding: 0 10px;
}

.image {
  width: 100%;
  height: 100px;
  object-fit: contain;
}
</style>
